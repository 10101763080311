import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }
  
  /**
   * Lists user detail
   * @returns  
   */
  public listUserDetail(page:number,limit:number,search:string): Observable<any>{ 
   return this.http.get(`${environment.API_BASE_URL}admin/user-list?page=${page}&limit=${limit}&search=${search}`);
  }
  
 /**
  * Creates user detail
  * @param params 
  * @returns  
  */
 public createUserDetail(params:any): Observable<any>{
  return this.http.post(`${environment.API_BASE_URL}admin/create-user`,params)
 }
 
 /**
  * patch user detail
  * @param id 
  * @returns user detail 
  */
 public getUserDetail(id:string): Observable<any>{
  return this.http.get(`${environment.API_BASE_URL}admin/user-details/${id}`)  

 }
 
 /**
  * Updates user detail
  * @param id 
  * @param params 
  * @returns detail 
  */
 public updateUserDetail(id:string,params:any): Observable<any>{
  return this.http.patch(`${environment.API_BASE_URL}admin/update-user/${id}`,params)
 }
 
 /**
  * Deletes uer detail
  * @param id 
  * @returns uer detail 
  */
 public deleteUserDetail(id:string): Observable<any>{
  return this.http.delete(`${environment.API_BASE_URL}admin/delete-user/${id}`)
 }
}
