<div class="filter-container">
  <div class="mt-4">
    @if (enumFilter.Booking==this.type) {
      <div class="mb-4 flex">
      <div class="mr-4">
        <p>From</p>
        <input type="date" id="from" class="date-range text-xs sm:text-sm text-gray-700" name="from">
      </div>
      <div class="mb-4">
        <p>To</p>
        <input type="date" id="to" class="date-range text-xs sm:text-sm text-gray-700" name="to">
      </div>
      </div>
      <div class="mb-4">
        <label for="destination" class="block mb-2 text-xs sm:text-sm font-medium text-gray-900 dark:text-white">Destination</label>
        <select id="destination" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option selected>Choose a destination</option>
          <option value="US">United States</option>
          <option value="CA">Canada</option>
          <option value="FR">France</option>
          <option value="DE">Germany</option>
        </select>
      </div>
      <div class="mb-4">
        <label for="bookingStatus" class="block mb-2 text-xs sm:text-sm font-medium text-gray-900 dark:text-white">Booking status</label>
        <select id="bookingStatus" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option selected>Choose a booking status</option>
          <option value="Confirmed">Confirmed</option>
          <option value="Pending">Pending</option>
          <option value="Cancelled">Cancelled</option>
          <option value="Refunded">Refunded</option>
        </select>
      </div>
    }

    @if(enumFilter.User==this.type){
      <div class="mb-4">
        <label for="status" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Status</label>
        <select id="status" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option selected>Choose a status</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>
      <!-- <div class="mb-4">
        <label for="emailVerified" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email Verified</label>
        <select id="emailVerified" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option selected>Choose an email Verified</option>
          <option value="0">Verified</option>
          <option value="1">Unverified</option>
        </select>
      </div> -->
    }
    @if (enumFilter.Payment==this.type) {
      <label for="paymentDate" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Payment Date</label>
      <div class="mb-4 flex">
        <div class="mr-4">
          <p>From</p>
          <input type="date" id="from" class="date-range text-xs sm:text-sm text-gray-700" name="from">
        </div>
        <div class="mb-4">
          <p>To</p>
          <input type="date" id="to" class="date-range text-xs sm:text-sm text-gray-700" name="to">
        </div>
        </div>
      <div class="mb-4">
        <label for="paymentMethod" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Payment method</label>
        <select id="paymentMethod" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option selected>Choose an payment method</option>
          <option value="Debit">Debit</option>
          <option value="Credit">Credit</option>
          <option value="Upi">Upi</option>
        </select>
      </div>
   
      <div class="mb-4">
        <label for="paymentStatus" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Payment status</label>
        <select id="paymentStatus" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option selected>Choose a payment status</option>
          <option value="Pending">Pending</option>
          <option value="Cancelled">Completed</option>
          <option value="Refunded">Refunded</option>
        </select>
      </div>
    }

    <div class="flex justify-end mt-3"> 
      <app-button label="Close" bgColor="#f9fafb" color="black" (action)="closeFilter.emit()"></app-button>
      <app-button label="Apply" class="ml-2"></app-button>
    </div>
  </div>
</div>
