import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterType } from 'src/app/shared/enums/filterType.enum';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss'
})
export class FilterComponent {
  @Output() closeFilter = new EventEmitter();
  @Input() type : string =''
  enumFilter = FilterType
 constructor() { 
 
  }
  
}

    

