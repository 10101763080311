import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(private http:HttpClient) { }
  
  /**
   * Flights booking list
   * @returns booking list 
   */
  public flightBookingList(): Observable<any>{ 
    return this.http.get(`${environment.API_BASE_URL}admin/flight-booking-list`);
   }
   
  /**
   * Hotels booking list
   * @returns booking list 
   */
  public hotelBookingList(): Observable<any>{ 
    return this.http.get(`${environment.API_BASE_URL}admin/hotel-booking-list`);
}

/**
 * Taxis booking list
 * @returns booking list 
 */
public taxiBookingList(): Observable<any>{ 
  return this.http.get(`${environment.API_BASE_URL}admin/taxi-booking-list`);
}

/**
 * Booking list
 * @returns list 
 */
public bookingList(page:number,limit:number,search:string): Observable<any>{ 
  return this.http.get(`${environment.API_BASE_URL}admin/booking-list?page=${page}&limit=${limit}&search=${search}`);
}

/**
 * Gets booking detail
 * @param id 
 * @returns booking detail 
 */
public getBookingDetail(id:string): Observable<any>{
  return this.http.get(`${environment.API_BASE_URL}admin/booking-details?id=${id}`)
}
}