interface Breadcrumb {
  label: string,
  path: string
}
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrl: './breadcrumbs.component.scss'
})
export class BreadcrumbsComponent {
  constructor(private router: Router) { }
  @Input() breadcrumbs: Breadcrumb[] = [];
  /**
   * Redirects to the specified path.
   *
   * @param {string} path - the path to navigate to
   * @return {void} 
   */
  redirect(path: string): void {
    path && this.router.navigate([path]);
  }
}
