import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UserService } from 'src/app/features/admin/pages/user/services/user.service';
import { UserComponent } from 'src/app/features/admin/pages/user/user.component';
import { SubAdminService } from 'src/app/features/admin/sub-admin/services/sub-admin.service';
import { BookingService } from 'src/app/features/sales/pages/booking-management/services/booking.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss'
})
export class PaginationComponent  {
  name!: string;
  @Input() currentPage: number = 1; // or any default value
  @Input() limit: number = 10; // default value
  @Input() totalItems: number = 0; // default value
  @Output() pageChanged: EventEmitter<number> = new EventEmitter();
  @Output() advertisingList: EventEmitter<any> = new EventEmitter();
  @Output() startingIndex: EventEmitter<number> = new EventEmitter();
  @Input() totalPages: number = 0; // default value
  @Input() totalDocs: number = 0; // default value
  @Input() apiUrl: string = ''; // default value
  @Input() filterData: string = ''; // default value
  @Input() firstIndex: number = 0; // default value
  @Input() filter!: string; // This can remain as is, assuming it will be provided later
  values='';
  isAdmin:boolean=true;
   constructor(private user:UserService,private subAdmin:SubAdminService,private booking:BookingService){
      
   }

   /**
    * Gets pagination list
    * @returns  
    */
   public getPaginationList() {
    let apiCall$;
    switch (this.apiUrl) {
        case 'user':
        apiCall$=this.user.listUserDetail(this.currentPage,this.limit,this.values);
        break;
      case 'sub-admin':
      apiCall$=this.subAdmin.listSubAdmin(this.currentPage,this.limit,this.values);
      break;
       case 'booking':
        apiCall$=this.booking.bookingList(this.currentPage,this.limit,this.values);
        break;
      default:
          console.error('Invalid API type');
          return;
    }

    apiCall$.subscribe({
      next: (res: any) => { 
        this.totalItems = res.data.totalItems;
        this.totalPages = res.data.totalPages;
        this.totalDocs = res.data.totalDocs;
        this.firstIndex = res.data.pagingCounter;
        this.startingIndex.emit(this.firstIndex);
        this.advertisingList.emit(res.data.docs);
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }

 /**
   * Changes page
   * @param page
   */
 changePage(page: number): void {
  if (page >= 1 && page <= this.totalPages) {
    this.currentPage = page;
    this.pageChanged.emit(page);
  }
}

/**
 * Go to previous page
 */
goToPreviousPage(): void {
  if (this.currentPage > 1) {
    this.currentPage--;

    this.getPaginationList();
  }
}

/**
 * Go to next page
 */
goToNextPage(): void {

  if (this.currentPage < this.totalPages) {
    this.currentPage++;
    this.getPaginationList();
  }
}

/**
 * Go to page
 * @param pageNumber 
 */
goToPage(pageNumber: any): void {
    
  if (pageNumber && pageNumber >= 1 && pageNumber <= this.totalPages && pageNumber !== this.currentPage) {
    this.currentPage = pageNumber;

    this.getPaginationList();
  }
}

/**
 * Gets page numbers
 */
get pageNumbers(): (number | string)[] {
  const totalPagesToShow = 3;
  const currentPageIndex = this.currentPage - 1;

  if (this.totalPages <= totalPagesToShow) {
    return Array.from({ length: this.totalPages }, (_, index) => index + 1);
  } else {
    const halfPagesToShow = Math.floor(totalPagesToShow / 2);
    let startPage: number;
    let endPage: number;

    if (currentPageIndex <= halfPagesToShow) {
      startPage = 1;
      endPage = totalPagesToShow;
    } else if (currentPageIndex + halfPagesToShow >= this.totalPages) {
      startPage = this.totalPages - totalPagesToShow + 1;
      endPage = this.totalPages;
    } else {
      startPage = currentPageIndex - halfPagesToShow + 1;
      endPage = currentPageIndex + halfPagesToShow;
    }

    let pages: (number | string)[] = Array.from({ length: endPage - startPage + 1 }, (_, index) => index + startPage);
      if (startPage > 1) {
        pages = [1, ...pages];
    
    }
      if (endPage < this.totalPages) {
      pages = [...pages, '...', this.totalPages];
    }

    return pages;
  }
}

/**
 * @param{number}firstIndex
 * @param{number}totalItems
 * @param{number}itemsPerPage
 */
calculateLastIndex(firstIndex: number, totalDocs: number, itemsPerPage: number): number {
   
  const lastIndex = firstIndex + itemsPerPage - 1;
   return Math.min(lastIndex, totalDocs);
}



}
