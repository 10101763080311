<nav class="flex items-center justify-between items-start md:items-center space-y-3 px-0 sm:px-4"
  aria-label="Table navigation">
  <span class="text-base sm:text-lg font-normal dark:text-gray-400 mt-2">{{firstIndex}}
    Showing
    <span class="font-semibold text-[#0E0E2C] dark:text-white">{{firstIndex}}-{{calculateLastIndex(firstIndex,
      totalItems, limit) }}</span>
      
    of
    
    <span class="font-semibold text-[#0E0E2C] dark:text-white">{{totalItems}}</span>
  </span>
  <ul class="flex items-center space-y-0 m-0 text-base">
    <li (click)="goToPreviousPage()">
      <a
        class="cursor-pointer flex items-center justify-center px-3 h-8 sm:px-4 sm:h-10 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-md hover:bg-[#1b3faa] hover:text-gray-700">
        <span class="sr-only">Previous</span>
        <svg class="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M5 1 1 5l4 4" />
        </svg>
      </a>
    </li>

    <li *ngFor="let page of pageNumbers ; let i = index">
      <a (click)="goToPage(page)"
        [ngClass]="page == currentPage ? 'text-white bg-[#1b3faa] border-[#3C3C4C]' : 'bg-white text-white-500 '"
        class="cursor-pointer flex items-center justify-center px-3 h-8 sm:px-4 sm:h-10 leading-tight text-sm md:text-base  border bg-primary-700 hover:bg-primary-800  hover:text-gray-700 focus:outline-none focus:border-gray-300">{{
        page }}</a>
    </li>
  
    <li>
      <a (click)="goToNextPage()"
        class="flex items-center justify-center px-3 h-8 sm:px-4 sm:h-10 leading-tight text-sm md:text-base text-white-500 bg-white border border-gray-300 rounded-e-md hover:bg-[#F9EFE780] hover:text-gray-700">
        <span class="sr-only">Next</span>
        <svg class="w-3 h-3 rtl:rotate-180" aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m1 9 4-4-4-4" />
        </svg>
      </a>
    </li>
  </ul> 
</nav>
