<div class="booking-data relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="tableHeader"
            (cdkDragReleased)="true" (cdkDropListDropped)="dropCol($event)">
                @for ( item of tableHeader; track item.title) {
                <th scope="col" class="px-4 py-3 truncate" cdkDrag (mousedown)="mouseDown($event)" cdkDragLockAxis="x">
                    @if(item.display){
                    <span class="text-sm sm:text-base font-medium cursor-move">{{item.title}}</span>
                    }
                    @if(item.sort){
                    <i class='bx bxs-sort-alt cursor-pointer'></i>
                    }
                </th>
                }
            </tr>
        </thead>
        <tbody>
            @for (row of tableData; track row) {
                <tr
                    class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                    @for (header of tableHeader; track header.title) {
                        <td class="text-sm px-4 py-3 truncate" [ngStyle]="{'color': handleStatusColor(header.status ? row[header.key] : '')}">

                            @if(isDate(row[header.key])) {
                                {{row[header.key] | date:'short'}}
                            }
                            <div class="flex gap-2">
                                @if (header.view) {
                                    <app-button  icon="bx bx-show" (action)="openConsole.emit(row)"></app-button>
    
                                }
                                @if (header.edit) {
                                    <app-button  icon="bx bx-edit" (action)="edit.emit(row)" class="ml-2"></app-button>
                                }
                                @if (header.delete) {
                                    <app-button  icon="bx bx-trash" (action)="delete.emit(row)" class="ml-2"></app-button>
                                }
                            </div>
                            @if (!isDate(row[header.key])) {
                                {{row[header.key]}}
                            }
                        </td>
                    }
                </tr>
            }
        </tbody>
               
    </table>
</div>