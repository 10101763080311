<nav
  class="inline-flex px-2 sm:px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700"
  aria-label="Breadcrumb">
  <ol class="inline-flex items-center space-x-1 rtl:space-x-reverse">
    @for( breadcrumb of breadcrumbs; track breadcrumb; let i = $index){
    <li [ngClass]="{'aria-current': i == breadcrumbs.length - 1}">
      <div class="flex items-center">
        <svg class="rtl:rotate-180 block w-3 h-3 mx-1 text-gray-400 " aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m1 9 4-4-4-4" />
        </svg>
        @if(i != breadcrumbs.length - 1){
        <span
          class="ms-1 text-xs sm:text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white cursor-pointer"
          (click)="redirect(breadcrumb.path)">{{breadcrumb.label}}</span>
        } @else {
        <span
          class="ms-1 text-xs sm:text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400 cursor-pointer">{{breadcrumb.label}}</span>
        }
      </div>
    </li>
    }
  </ol>
</nav>